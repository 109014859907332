<script lang="ts">
	import cn from 'classnames';
	import { cartStorage, preferencesStorage } from '../../../stores/preferences';
	import { cartFreeDelivery } from '../../const';

	export let popup: boolean = false;
	export let isCart: boolean = false;
</script>

{#if $preferencesStorage.currency !== 'EUR'}
<div class={cn('w-full bg-white md:bg-gray-alabaster')}>
	<div
		class={cn(
			'px-8 py-6',
			popup && 'md:flex md:flex-row md:items-center md:justify-between md:gap-x-8 md:px-16 md:py-9'
		)}
	>
		<div
			class={cn(
				'text-center text-lg text-black',
				popup ? 'flex flex-col md:flex-row md:gap-x-2' : 'w-full'
			)}
		>
			{#if $cartStorage.new_articles.lines.reduce((acc, item) => acc + item?.total_price, 0) < cartFreeDelivery}
				<span class="font-bold text-red"> Chcete poštovné zdarma? </span>
				<span
					>Schází Vám nákup za <span class="font-bold"
						>{cartFreeDelivery -
							$cartStorage.new_articles.lines?.reduce(
								(acc, item) => acc + item?.total_price,
								0
							)}&nbsp;Kč</span
					></span
				>
			{:else}
				Gratulujeme, máte nárok na poštovné zdarma!
			{/if}
		</div>

		<div class={cn('h-4 rounded-full bg-gray-200', popup ? 'grow mdmax:mt-5' : 'mx-auto my-4')}>
			{#if ($cartStorage.total_price / cartFreeDelivery) * 100 < 100}
				<div
					class="bg-primary h-4 rounded-full"
					style="width: {($cartStorage.total_price / cartFreeDelivery) * 100}%"
				/>
			{:else}
				<div class="h-4 w-full rounded-full bg-green" />
			{/if}
		</div>
	</div>

	<!--<div class="w-full md:bg-white py-6 mdmax:border-t border-gray-concrete md:border md:p-0">
		<div class="w-full px-8 grid gap-x-4 grid-cols-4 md:p-5">
			<div class="flex flex-wrap items-center">
				<img src={produkt1} alt="" class="w-full h-auto" />
			</div>
			<div class="flex flex-col col-span-3 lg:flex-row lg:items-center lg:gap-x-8">
				<a href="/" class="font-bold">
					Automatický sytič DellOrto - podkova<br />
					160 Kč
				</a>
				<div class="flex flex-col gap-y-2 items-center text-center">
					<span class="text-lg hidden lg:block">160 Kč</span>
					<Button href="/" class="self-start mt-4 lg:mt-0">Do košíku</Button>
				</div>
			</div>
		</div>
	</div>-->
</div>
	{:else}
	{#if isCart}
		<div></div>
	{/if}
{/if}